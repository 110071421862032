import { type NextPage } from "next";
import Head from "next/head";
import Link from "next/link";



//Components
import HomePage from "~/pages/home";

const Home: NextPage = () => {
  
  return (
    <HomePage></HomePage>
  );
};

export default Home;
